<template>
  <div class="navbarr" id="navbarr">
    <div class="temp"></div>
    <div class="content">
      <!-- <div class="top-bar">
        <div class="items">
          <router-link class="item active" to="/ManageInvestors">
            <span>Investors</span>
          </router-link>
          <router-link class="item" to="/ManageInvestments">
            <span>Investments</span>
          </router-link>
          <router-link class="item" to="/RegistrationRequests">
            <span>Registration Requests</span>
          </router-link>
        </div>
        <div class="">
          <div class="mode" @click="toggleMode">
            <v-icon icon="mdi-theme-light-dark" />
          </div>
          <div class="notification">
            <v-icon icon="mdi-chat-outline" />
          </div>
          <div class="profile">
            <img src="@/assets/images/navbar/person.jpg" alt="Person Image" />
            <div class="name">
            <span>John John</span>
            <span>Founder</span>
          </div>
          </div>
        </div>
      </div> -->
      <div class="top-bar">
        <div class="mode" @click="toggleMode">
          <v-icon icon="mdi-theme-light-dark" />
        </div>
        <div class="notification">
          <v-icon icon="mdi-bell-outline" />
        </div>
        <div class="profile">
          <img src="@/assets/images/navbar/person.jpg" alt="Person Image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppNavbar",
  data() {
    return {
      mode: "light",
    };
  },
  methods: {
    toggleMode() {
      if (this.mode == "light") {
        this.mode = "dark";
      } else {
        this.mode = "light";
      }
    },
  },
  mounted() {
    //  Sticky Menu
    var $window = window.$(window);
    $window.on("scroll", function () {
      if ($window.scrollTop() > 20) {
        window.$(".navbarr").addClass("sticky");
      } else {
        window.$(".navbarr").removeClass("sticky");
      }
    });
    // On Link Clicked
    window.$("body").on("click", ".items .item", function () {
      window.$(this).addClass("active").siblings().removeClass("active");
    });
  },

  components: {},
};
</script>

<style>
.navbarr {
  display: flex;
}
.navbarr.sticky {
  position: sticky;
  width: 100%;
  top: 0;
  margin-top: 0;
  z-index: 99;
}

/* Temp  */
.navbarr .temp {
  min-width: 265px;
  transition: all 0.5s;
}
.close-rail .navbarr .temp {
  min-width: 70px;
  transition: all 0.5s;
}

/* Content */
.navbarr .content {
  width: -webkit-fill-available;
}
.navbarr .content .top-bar {
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.5rem;
  height: 75px;
  /* box-shadow: 0 1px 5px #ccc !important; */
}
.navbarr .content .top-bar a {
  margin-right: 2vh;
  text-decoration: none;
  font-weight: 550;
  color: #69728c;
  padding: 0.8rem;
  border-radius: 6px;
}
.navbarr .content .top-bar .items .item.active {
  background: #f9f9f9;
  color: #1b84ff;
}
.navbarr .content .top-bar .mode,
.navbarr .content .top-bar .notification {
  margin-right: 1rem;
  padding: 0.5rem;
  transition: all 0.5s;
  cursor: pointer;
  border-radius: 10px;
  display: inline;
}
.navbarr .content .top-bar .mode:hover,
.navbarr .content .top-bar .notification:hover {
  background-color: #f9f9f9;
  color: #1b84ff;
}
.navbarr .content .top-bar i {
  color: #878a99;
  transition: all ease-in-out .2s;
}
.navbarr .content .top-bar i:hover{
  color: #1b84ff;
}
.navbarr .content .top-bar .profile {
  border-radius: 5px;
  color: #7fb4f9;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;
  padding: 1rem;
}
.navbarr .content .top-bar .profile img {
  width: 2rem;
  height: 2rem;
  border-radius: 10px;
  margin-right: 0.5rem;
}
@media (max-width: 767px) {
  .navbarr .content .top-bar .profile img {
    margin-right: 0;
  }
  .navbarr .content .top-bar .profile .name {
    display: none;
  }
}
.navbarr .content .top-bar .profile .name span:first-of-type {
  color: #905057;
}
.navbarr .content .top-bar .profile .name span:last-of-type {
  color: #878a99;
  display: block;
  font-size: 0.7rem;
}
</style>
