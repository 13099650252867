<template>
  <div class="registrationRequests" id="manageInvestors">
    <div class="temp"></div>
    <div class="content">
      <!--Investors Table-->
      <div class="card">
        <div class="row headerCard">
          <h5 class="col-sm-6">Registration Requests</h5>
        </div>
        <table>
          <thead>
            <tr>
              <th scope="col">User ID</th>
              <th scope="col">User Name</th>
              <th scope="col">Email</th>
              <th scope="col">Operation</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td data-label="User ID">Visa - 3412</td>
              <td data-label="Deposit Amount">Ali Ahmad</td>
              <td data-label="Deposit Date">Alid@gmail.com</td>
              <td data-label="Operation">
                <button class="btn btn-success">Accepte</button>
                <button
                  class="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#deletModal"
                >
                  Reject
                </button>
              </td>
            </tr>
            <tr>
              <td data-label="User ID">Visa - 3412</td>
              <td data-label="Deposit Amount">Samer Saaed</td>
              <td data-label="Deposit Date">Saed@gmail.com</td>
              <td data-label="Operation">
                <button class="btn btn-success">Accepte</button>
                <button
                  class="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#deletModal"
                >
                  Reject
                </button>
              </td>
            </tr>
            <tr>
              <td data-label="User ID">Visa - 3412</td>
              <td data-label="Deposit Amount">Evon Haddad</td>
              <td data-label="Deposit Date">Ead@gmail.com</td>
              <td data-label="Operation">
                <button class="btn btn-success">Accepte</button>
                <button
                  class="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#deletModal"
                >
                  Reject
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <AppDeleteModal modalTitle=" Delete User" />
</template>

<script>
import AppDeleteModal from "@/components/global/AppDeleteModal.vue";
export default {
  name: "RegistrationRequests",
  data() {
    return {};
  },
  methods: {},
  components: {
    AppDeleteModal,
  },
};
</script>

<style AppDeleteModal>
.registrationRequests {
  display: flex;
}

/* Temp  */
.registrationRequests .temp {
  min-width: 250px;
  transition: all 0.5s;
}
.close-rail .registrationRequests .temp {
  min-width: 70px;
  transition: all 0.5s;
}

/* Content */
.registrationRequests .content {
  width: -webkit-fill-available;
  margin-top: 5vh;
  padding: 0 5vh;
}
/* Card And Add Button */
.registrationRequests .content .card {
  margin-bottom: 5vh;
  padding: 2rem 2.25rem;
  --bs-card-border-color: #f1f1f4;
  --bs-card-border-radius: 0.625rem;
  --bs-card-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}
.registrationRequests .content .card .headerCard {
  display: flex;
  justify-content: space-between;
  padding: 0px 0.7rem;
}
.registrationRequests .content .card h5 {
  font-weight: 600;
  font-size: 17px;
}

/* registrationRequests Table */
.registrationRequests table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  margin: 2vh 0;
}
.registrationRequests table tr {
  padding: 0.35em;
  border-radius: 10px;
  border-bottom: 1px solid #f9f9f9;
}

.registrationRequests table th,
.registrationRequests table td {
  padding: 0.625em;
  color: #071437;
  font-weight: 550;
  font-size: 0.8rem;
  font-size: 13px;
  text-align: center;
}
.registrationRequests table th {
  background: #f9f9f9;
}

.registrationRequests table th {
  font-weight: 600;
  font-size: 0.85em;
  letter-spacing: 0.1em;
  color: #a6a1b7;
}
.registrationRequests table button {
  margin: 0 2px;
  font-weight: 200;
  font-size: 12px;
}
.registrationRequests table tr:last-child {
  border-bottom: none;
}
/* Responsive Table */
@media screen and (max-width: 1100px) {
  .registrationRequests table {
    border: 0;
    box-shadow: none;
  }

  .registrationRequests table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .registrationRequests table tr {
    display: block;
  }

  .registrationRequests table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .registrationRequests table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .registrationRequests table td:last-child {
    border-bottom: 0;
  }
}
</style>
