<template>
  <div class="delete-modal" id="delete-modal">
    <!-- Modal -->
    <div
      class="modal fade"
      id="deletModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Are You Sure ?</div>
          <div class="modal-footer">
            <button type="button" class="btn no-btn" data-bs-dismiss="modal">
              No
            </button>
            <button type="button" class="btn yes-btn" data-bs-dismiss="modal">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteModalView",
  props: ["modalTitle"],
  methods: {},
  mounted() {},
  components: {},
};
</script>

<style>
.delete-modal .modal .modal-content {
  border-radius: 1rem;
  box-shadow: 1px 1px 5px #856161;
}
.delete-modal .modal .modal-title {
  color: #dc3545;
  font-weight: bold;
}
.delete-modal .modal .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
  margin: auto;
  font-size: 2rem;
}
.delete-modal .modal .no-btn {
  background-color: #6c757d;
  color: #fff;
}
.delete-modal .modal .yes-btn {
  background-color: #dc3545;
  color: #fff;
}
.delete-modal .modal .no-btn:hover,
.delete-modal .modal .yes-btn:hover {
  box-shadow: 1px 1px 5px #ccc;
}
</style>
