<template>
  <app-navbar v-if="!main" />
  <app-sidebar v-if="!main" @toggle-rail="toggleRail" />
  <router-view />
</template>

<script>
import AppSidebar from "@/components/global/AppSidebar.vue";
import AppNavbar from "@/components/global/AppNavbar.vue";

export default {
  name: "App",
  data() {
    return {
      main: true,
    };
  },
  methods: {
    toggleRail(rail) {
      if (rail) {
        window.$("#app").addClass("close-rail");
      } else {
        window.$("#app").removeClass("close-rail");
      }
    },
    checkMainLocation(location) {
      if (location != "/" && location != "/ForgetPassword") {
        this.main = 0;
      } else {
        this.main = 1;
      }
    },
  },
  mounted() {
    // Get Current Location
    var location = window.location.pathname;

    this.checkMainLocation(location);

    // On URL Change
    setInterval(() => {
      const currUrl = window.location.pathname;
      if (currUrl != location) {
        location = currUrl;
        this.checkMainLocation(location);
      }
    }, 60);
  },
  components: {
    AppSidebar,
    AppNavbar,
  },
};
</script>

<style>
/* @font-face {
  font-family: Inter-Regular;
  src: url(@/assets/fonts/static/Inter-Regular.ttf);
} */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
#app {
  font-family: Inter, Helvetica, "sans-serif";
  /* font-family: Inter-Regular; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fcfcfc;
}
.vl-overlay.vl-active {
  border-radius: 2rem;
}
.modal {
  scrollbar-width: thin;
  scrollbar-color: #f1f1f4 transparent;
}
</style>
