import { createRouter, createWebHistory } from 'vue-router'
import LoginView from "@/views/LoginView.vue";
import ManageInvestors from '@/views/ManageInvestors.vue'
import ManageInvestments from '@/views/ManageInvestments.vue'
import RegistrationRequests from '@/views/RegistrationRequests.vue'
import ProfileView from '@/views/ProfileView.vue'

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: '/ManageInvestors',
    name: 'ManageInvestors',
    component: ManageInvestors
  },
  {
    path: '/ManageInvestments',
    name: 'ManageInvestments',
    component: ManageInvestments
  },
  {
    path: '/RegistrationRequests',
    name: 'RegistrationRequests',
    component: RegistrationRequests
  },
  {
    path: '/ProfileView',
    name: 'ProfileView',
    component: ProfileView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
