<template>
  <div class="header" id="header">
    <v-card>
      <v-layout>
        <v-navigation-drawer
          v-model="drawer"
          :rail="rail"
          permanent
          @click="openNavigationDrawer"
        >
          <div class="head" v-if="!rail">
            <div class="logo">
              <router-link to="/">
                <img src="@/assets/images/default-dark.svg" alt="Logo Icon" />
              </router-link>
              <v-btn
              class="sideIcon"
                variant="text"
                icon="mdi-arrow-expand-left"
                @click.stop="closeNavigationDrawer"
              ></v-btn>
            </div>
          </div>
          <div class="items">
            <span class="label" v-if="!rail">Manage</span>
            <router-link class="item active" to="/ManageInvestors">
              <v-icon icon="mdi-home-city" />
              <span>Investors</span>
            </router-link>
            <router-link class="item" to="/ManageInvestments">
              <v-icon icon="mdi-group" />
              <span>Investments</span>
            </router-link>
            <router-link class="item" to="/RegistrationRequests">
              <v-icon icon="mdi-home" />
              <span>Registration Requests</span>
            </router-link>
          </div>
        </v-navigation-drawer>
        <v-main></v-main>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      drawer: true,
      rail: false,
    };
  },
  props: [],
  methods: {
    openNavigationDrawer() {
      let windowWidth = window.innerWidth;
      if (windowWidth > 767) {
        this.rail = false;
        this.$emit("toggle-rail", this.rail);
      }
    },
    closeNavigationDrawer() {
      this.rail = true;
      this.$emit("toggle-rail", this.rail);
    },
  },
  mounted() {
    let that = this;

    // Init Rail Of App Header
    let windowWidth = window.innerWidth;
    if (windowWidth < 767) {
      that.rail = true;
      that.$emit("toggle-rail", that.rail);
    }

    // On Resize Body
    window.$(window).on("resize", function (e) {
      windowWidth = e.target.innerWidth;
      if (windowWidth < 767) {
        that.rail = true;
        that.$emit("toggle-rail", that.rail);
      }
    });

    // On Link Clicked
    window.$("body").on("click", ".header .items .item", function () {
      window.$(this).addClass("active").siblings().removeClass("active");
    });
  },
  components: {},
};
</script>

<style>
.header .v-navigation-drawer {
  background-color: #0d0e12;
  color: #fff;
  border: none;
  width: 265px !important;
  transition: all 0.5s;
}
.close-rail .header .v-navigation-drawer {
  width: 70px !important;
  transition: all 0.5s;
}
.close-rail .header .v-navigation-drawer .items .item {
  margin-bottom: 2vh;
}

.header .v-navigation-drawer .items .item:hover {
  color: #fff;
}
.close-rail .header .v-navigation-drawer .items .item.active {
  background: none;
}

/* Head */
.header .v-navigation-drawer .head {
  height: 75px;
  padding: 0 19px;
  border-bottom: 1px dashed #1f212a;
  align-items: center;
  display: flex;
}
.header .v-navigation-drawer .logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .v-navigation-drawer .logo .sideIcon{
  position: absolute;
  right: -25px;
}
.header .v-navigation-drawer .logo img {
  width: 120px;
  height: 25px;
}
.header .v-navigation-drawer .logo .v-btn i {
  color: #878a99;
    font-size: 0.8rem;
    background: white;
    border-radius: 5px;
    border: 1px solid #f1f1f2;
    padding: .9rem;
}

/* Items */
.header .v-navigation-drawer .items {
  overflow: auto;
  height: 88%;
  padding: 0.8rem;
}
.header .v-navigation-drawer .items span.label {
  color: #636674;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 550;
  padding: 0.65rem 0;
  display: block;
}
.header .v-navigation-drawer .items::-webkit-scrollbar {
  display: none;
}
.close-rail .header .v-navigation-drawer .items {
  height: 100%;
  padding-top: 2rem;
}
.header .v-navigation-drawer .items .item {
  text-decoration: none;
  color: #878a99;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 9px 13px;
  margin-bottom: 1px;
  transition: all 0.3s;
  border-radius: 8px;
}
.header .v-navigation-drawer .items .item.active {
  transition: color 0.2s ease;
  background-color: #1c1c21;
  color: #f5f5f5;
}
.close-rail .header .v-navigation-drawer .items .item {
  padding: 0 0.5rem;
  justify-content: center;
}
.header .v-navigation-drawer .items .item .v-icon {
  margin-right: 0.5rem;
}
.close-rail .header .v-navigation-drawer .items .item .v-icon {
  margin-right: 0;
}
.header .v-navigation-drawer .items .item span {
  font-size: 1rem;
}
.close-rail .header .v-navigation-drawer .items .item span {
  display: none;
}
</style>
