<template>
  <div class="manageInvestments" id="manageInvestors">
    <div class="temp"></div>
    <div class="content">
      <!--Investments Table-->
      <div class="card">
        <div class="row headerCard">
          <h5 class="col-sm-6">Manage Investments</h5>
          <button
            class="btn col-sm-6"
            data-bs-toggle="modal"
            data-bs-target="#addInvestmentModal"
          >
            <v-icon icon="mdi-plus" /> Add Investment
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th scope="col">Investment ID</th>
              <th scope="col">Investment Name</th>
              <th scope="col">Maturity Period</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td data-label="Investment ID">Visa - 3412</td>
              <td data-label="Deposit Amount">Ali Ahmad</td>
              <td data-label="Deposit Date">290</td>
              <td data-label="Maturity Date">
                <span class="approved">Approved</span>
              </td>
              <td>
                <ul>
                  <li>
                    <v-icon
                      icon="mdi-delete-empty-outline"
                      class="mdi"
                      data-bs-toggle="modal"
                      data-bs-target="#deletModal"
                    />
                  </li>
                  <li>
                    <v-icon
                      icon="mdi-pen"
                      data-bs-toggle="modal"
                      data-bs-target="#updateInvestment"
                    />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td data-label="Investment ID">Visa - 3412</td>
              <td data-label="Deposit Amount">Samer Saaed</td>
              <td data-label="Deposit Date">190</td>
              <td data-label="Maturity Date">
                <span class="rejected">Rejected</span>
              </td>
              <td>
                <ul>
                  <li>
                    <v-icon
                      icon="mdi-delete-empty-outline"
                      class="mdi"
                      data-bs-toggle="modal"
                      data-bs-target="#deletModal"
                    />
                  </li>
                  <li>
                    <v-icon
                      icon="mdi-pen"
                      data-bs-toggle="modal"
                      data-bs-target="#updateInvestment"
                    />
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td data-label="Investment ID">Visa - 3412</td>
              <td data-label="Deposit Amount">Evon Haddad</td>
              <td data-label="Deposit Date">77</td>
              <td data-label="Maturity Date">
                <span class="success">Success</span>
              </td>
              <td>
                <ul>
                  <li>
                    <v-icon
                      icon="mdi-delete-empty-outline"
                      class="mdi"
                      data-bs-toggle="modal"
                      data-bs-target="#deletModal"
                    />
                  </li>
                  <li>
                    <v-icon
                      icon="mdi-pen"
                      data-bs-toggle="modal"
                      data-bs-target="#updateInvestment"
                    />
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <AppDeleteModal modalTitle=" Delete Investment" />
  <AppAddInvestment />
  <AppUpdateInvestment />
</template>

<script>
import AppDeleteModal from "@/components/global/AppDeleteModal.vue";
import AppAddInvestment from "@/components/global/AppAddInvestment.vue";
import AppUpdateInvestment from "@/components/global/AppUpdateInvestment.vue";
export default {
  name: "ManageInvestments",
  data() {
    return {};
  },
  methods: {},
  components: {
    AppDeleteModal,
    AppAddInvestment,
    AppUpdateInvestment,
  },
};
</script>

<style>
.manageInvestments {
  display: flex;
}

/* Temp  */
.manageInvestments .temp {
  min-width: 250px;
  transition: all 0.5s;
}
.close-rail .manageInvestments .temp {
  min-width: 70px;
  transition: all 0.5s;
}

/* Content */
.manageInvestments .content {
  width: -webkit-fill-available;
  margin-top: 5vh;
  padding: 0 5vh;
}
/* Card And Add Button */
.manageInvestments .content .card {
  margin-bottom: 5vh;
  padding: 2rem 2.25rem;
  --bs-card-border-color: #f1f1f4;
  --bs-card-border-radius: 0.625rem;
  --bs-card-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}
.manageInvestments .content .card .headerCard {
  display: flex;
  justify-content: space-between;
  padding: 0px 0.7rem;
}
.manageInvestments .content .card h5 {
  font-weight: 600;
  font-size: 17px;
}
.manageInvestments .content .card button {
  background: #e9f3ff;
    color: #1b84ff;
    font-weight: 200;
    font-size: 13px;
    width: auto;
    padding: 9px 14px;
}
.manageInvestments .content .card button:hover {
  color: white;
  background-color: var(--bs-primary) !important;
}

/* Investments Table */
.manageInvestments table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  margin: 2vh 0;
}
.manageInvestments table tr {
  padding: 0.35em;
  border-radius: 10px;
  border-bottom: 1px solid #f9f9f9;
}

.manageInvestments table th,
.manageInvestments table td {
  padding: 0.625em;
  color: #071437;
  font-weight: 550;
  font-size: 13px;
  text-align: center;
}
.manageInvestments table th {
  background: #f9f9f9;
}

.manageInvestments table th {
  font-weight: 600;
  font-size: 0.85em;
  letter-spacing: 0.1em;
  color: #a6a1b7;
}
.manageInvestments table tr:last-child {
  border-bottom: none;
}
/* Status */
.manageInvestments .content table span {
  padding: 2px;
  border-radius: 5px;
}
.manageInvestments .content table span.approved {
  color: #f6c000;
  background: #fff8dd;
}
.manageInvestments .content table span.success {
  color: #17c653;
  background: #dfffea;
}
.manageInvestments .content table span.rejected {
  color: #f8285a;
  background: #ffeef3;
}
/* Status */
.manageInvestments .content table ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
}
.manageInvestments .content table ul li {
  background: #dcdee5;
  padding: 3px;
  margin: 0 2px;
  border-radius: 5px;
  color: #99a1b7;
  cursor: pointer;
}
.manageInvestments .content table ul li:hover {
  color: #1b84ff;
}
/* Responsive Table */
@media screen and (max-width: 1100px) {
  .manageInvestments table {
    border: 0;
    box-shadow: none;
  }

  .manageInvestments table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .manageInvestments table tr {
    display: block;
  }

  .manageInvestments table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .manageInvestments table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .manageInvestments table td:last-child {
    border-bottom: 0;
  }
}
</style>
