<template>
  <div class="manageInvestors" id="manageInvestors">
    <div class="temp"></div>
    <div class="content">
      <!--Investors Table-->
      <div class="card">
        <div class="row headerCard">
          <h5 class="col-sm-6">Manage Investors</h5>
          <button
            class="btn col-sm-6"
            data-bs-toggle="modal"
            data-bs-target="#addInvestorModal"
          >
            <v-icon icon="mdi-plus" />
            Add Investor
          </button>
        </div>
        <table>
          <thead>
            <tr>
              <th scope="col">User ID</th>
              <th scope="col">User Name</th>
              <th scope="col">Email</th>
              <th scope="col">Status</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td data-label="User ID">Visa - 3412</td>
              <td data-label="">Ali Ahmad</td>
              <td data-label="Deposit Date">Ali@gmail.com</td>
              <td data-label="Maturity Date">
                <span class="approved">Approved</span>
              </td>
              <td>
                <ul>
                  <li>
                    <v-icon
                      icon="mdi-delete-empty-outline"
                      class="mdi"
                      data-bs-toggle="modal"
                      data-bs-target="#deletModal"
                    />
                  </li>
                  <li>
                    <router-link class="item active" to="/ProfileView">
                      <v-icon icon="mdi-dots-horizontal-circle-outline" />
                    </router-link>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td data-label="User ID">Visa - 3412</td>
              <td data-label="Deposit Amount">Samer Saaed</td>
              <td data-label="Deposit Date">Samer@gmail.com</td>
              <td data-label="Maturity Date">
                <span class="rejected">Rejected</span>
              </td>
              <td>
                <ul>
                  <li>
                    <v-icon
                      icon="mdi-delete-empty-outline"
                      class="mdi"
                      data-bs-toggle="modal"
                      data-bs-target="#deletModal"
                    />
                  </li>
                  <li>
                    <router-link class="item active" to="/ProfileView">
                      <v-icon icon="mdi-dots-horizontal-circle-outline" />
                    </router-link>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td data-label="User ID">Visa - 3412</td>
              <td data-label="Deposit Amount">Evon Haddad</td>
              <td data-label="Deposit Date">Evon@gmail.com</td>
              <td data-label="Maturity Date">
                <span class="success">Success</span>
              </td>
              <td>
                <ul>
                  <li>
                    <v-icon
                      icon="mdi-delete-empty-outline"
                      class="mdi"
                      data-bs-toggle="modal"
                      data-bs-target="#deletModal"
                    />
                  </li>
                  <li>
                    <router-link class="item active" to="/ProfileView">
                      <v-icon icon="mdi-dots-horizontal-circle-outline" />
                    </router-link>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <AppDeleteModal modalTitle=" Delete Investor" />
  <AppAddInvestor />
</template>

<script>
import AppDeleteModal from "@/components/global/AppDeleteModal.vue";
import AppAddInvestor from "@/components/global/AppAddInvestor.vue";
export default {
  name: "ManageInvestors",
  data() {
    return {};
  },
  methods: {},
  components: {
    AppDeleteModal,
    AppAddInvestor,
  },
};
</script>

<style AppDeleteModal>
.manageInvestors {
  display: flex;
}

/* Temp  */
.manageInvestors .temp {
  min-width: 250px;
  transition: all 0.5s;
}
.close-rail .manageInvestors .temp {
  min-width: 70px;
  transition: all 0.5s;
}

/* Content */
.manageInvestors .content {
  width: -webkit-fill-available;
  margin-top: 5vh;
  padding: 0 5vh;
}
/* Card And Add Button */
.manageInvestors .content .card {
  margin-bottom: 5vh;
  padding: 2rem 2.25rem;
  --bs-card-border-color: #f1f1f4;
  --bs-card-border-radius: 0.625rem;
  --bs-card-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}
.manageInvestors .content .card .headerCard {
  display: flex;
  justify-content: space-between;
  padding: 0px 0.7rem;
}
.manageInvestors .content .card h5 {
  font-weight: 600;
  font-size: 17px;
}
.manageInvestors .content .card button {
  background: #e9f3ff;
    color: #1b84ff;
    font-weight: 200;
    font-size: 13px;
    width: auto;
    padding: 9px 14px;
}
.manageInvestors .content .card button:hover {
  color: white;
  background-color: var(--bs-primary) !important;
}
/* Investors Table */
.manageInvestors table {
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  margin: 2vh 0;
}
.manageInvestors table tr {
  padding: 0.35em;
  border-radius: 10px;
  border-bottom: 1px solid #f9f9f9;
}

.manageInvestors table th,
.manageInvestors table td {
  padding: 0.625em;
  color: #071437;
  font-weight: 550;
  font-size: 0.8rem;
  font-size: 13px;
  text-align: center;
}
.manageInvestors table th {
  background: #f9f9f9;
}

.manageInvestors table th {
  font-weight: 600;
  font-size: 0.85em;
  letter-spacing: 0.1em;
  color: #a6a1b7;
}
.manageInvestors table tr:last-child {
  border-bottom: none;
}
/* Status */
.manageInvestors .content table span {
  padding: 2px;
  border-radius: 5px;
}
.manageInvestors .content table span.approved {
  color: #f6c000;
  background: #fff8dd;
}
.manageInvestors .content table span.success {
  color: #17c653;
  background: #dfffea;
}
.manageInvestors .content table span.rejected {
  color: #f8285a;
  background: #ffeef3;
}
/* Status */
.manageInvestors .content table ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
}
.manageInvestors .content table ul a{
  color: #99a1b7;
}
.manageInvestors .content table ul li {
  background: #dcdee5;
  padding: 3px;
  margin: 0 2px;
  border-radius: 5px;
  color: #99a1b7;
  cursor: pointer;
}
.manageInvestors .content table ul li:hover,
.manageInvestors .content table ul a:hover{
  color: #1b84ff;
}
/* Responsive Table */
@media screen and (max-width: 1100px) {
  .manageInvestors table {
    border: 0;
    box-shadow: none;
  }

  .manageInvestors table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .manageInvestors table tr {
    display: block;
  }

  .manageInvestors table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .manageInvestors table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .manageInvestors table td:last-child {
    border-bottom: 0;
  }
}
</style>
