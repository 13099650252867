<template>
  <div class="login row">
    <!--Header-->
    <div class="headerImage text-center">
      <div class="brand">
        <img src="../assets/images/custom-1.png" />
      </div>
    </div>
    <!--Login Form-->
    <div class="formContent col-lg-6 col-md-12">
      <div class="container">
        <div class="formContainer">
          <form class="form">
            <h4 class="text-center">Sign In</h4>
            <div class="mb-3">
              <label class="form-label">Email </label>
              <input
                class="form-control"
                id="email"
                type="email"
                placeholder="Please enter email"
                required=""
              />
            </div>
            <div class="mb-3">
              <label class="form-label">Password </label>
              <input
                class="form-control"
                id="password"
                type="password"
                placeholder="Enter password"
                required=""
              />
            </div>
            <div class="mt-4">
              <router-link class="item active" to="/ManageInvestments">
                <button class="btn btn-primary" type="submit">Continue</button>
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--Image Side-->
    <div class="image col-lg-6 col-md-12 text-center">
      <div class="container">
        <div class="h">
          <div class="brand">
            <img class="brand" src="../assets/images/custom-1.png" />
          </div>
          <img class="logoImg" src="../assets/images/auth-screens.png" />
          <h2>Fast, Efficient and Productive</h2>
          <p>
            In this kind of post, the blogger introduces a person they’ve
            interviewed and provides some background information about the
            interviewee and their work following this is a transcript of the
            interview.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginView",
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>
<style>
/* Header side */
.headerImage {
  background-image: url("../assets/images/auth-bg.png");
  background-size: cover;
  min-height: 10vh;
  padding: 21px 45px;
  display: none;
}
.headerImage .brand {
  width: 100%;
}
.headerImage .brand img {
  height: 60px;
  margin: auto;
}
/* Form Left Part In Login */
.formContent {
  flex: 1 auto;
  min-width: 0;
  padding-top: 90px !important;
  padding-left: 117px !important;
}
/* .formContent .h {
  width: 500px;
  padding: 0.5rem;
  margin-top: 10vh;
} */
.formContent h4 {
  font-weight: 600;
  color: #071437;
  margin-bottom: 5vh;
  font-size: 23px;
}

.formContent form label {
  font-weight: 600;
  color: #071437;
  font-size: 13px;
}
.formContent form input {
  margin-bottom: 5vh;
  background-color: #f9f9f9;
  width: 95%;
  padding: 9px 18px !important;
}
.formContent form input:focus {
  background: #f1f1f4;
  border: none;
  box-shadow: none;
}
.formContent form button {
  color: white;
  background-color: #1b84ff;
  width: 95%;
  font-weight: 600;
}
.formContent form button a {
  text-decoration: none;
  color: #fff;
}
/* Image Right Part In Login */
.image {
  background-image: url("../assets/images/auth-bg.png");
  background-size: cover;
  min-height: 100vh;
}
.image .container {
  padding: 3.75rem !important;
  margin-top: 6vh;
}
/* .image .brand {
  width: 100%;
} */
.image .brand {
  height: 75px;
  margin-right: auto !important;
  margin-left: auto !important;
  display: block;
}
.image .logoImg {
  width: 500px;
  margin: 4vh 0;
}
.image h2 {
  color: white;
  font-weight: 600;
  margin-bottom: 3vh;
}
.image p {
  font-size: 0.9rem;
  color: white;
}
@media (max-width: 1200px) {
  .image .logoImg {
    width: 275px;
  }
}
@media (max-width: 992px) {
  .formContent form {
    padding: 2vh 5vh !important;
  }
  .formContent {
    padding-left: 0 !important;
  }

  .image {
    display: none;
  }
  .headerImage {
    display: block;
  }
}
</style>
