<template>
  <div class="add-modal" id="add-modal">
    <!-- Add Investor Modal -->
    <div
      class="modal fade"
      id="addInvestorModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <h1 class="modal-title text-center" id="exampleModalLabel">Add Investor</h1>

          <form class="card">
            <div class="card-body">
              <div class="mb-3">
                <label class="form-label">First Name <span>*</span> </label>

                <input
                  class="form-control"
                  id="first"
                  type="text"
                  placeholder="Please enter First Name"
                  name=""
                  required
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Last Name <span>*</span></label>

                <input
                  class="form-control"
                  id="last"
                  type="text"
                  name=""
                  placeholder="Please enter Last Name"
                  required
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Email <span>*</span></label>

                <input
                  class="form-control"
                  id="email"
                  type="email"
                  name=""
                  placeholder="Please enter email"
                  required
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Phone Number<span>*</span> </label>

                <input
                  class="form-control"
                  id="phone"
                  type="number"
                  name=""
                  placeholder="Please enter Phone Number"
                  required
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Password <span>*</span></label>
                <input
                  class="form-control"
                  id="password"
                  type="password"
                  name=""
                  placeholder="Enter password"
                  required
                />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn no-btn" data-bs-dismiss="modal">
                Cancel
              </button>
              <button type="submit" class="btn yes-btn">Add</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppAddInvestor",
  props: ["modalTitle"],
  methods: {},
  mounted() {},
  components: {},
};
</script>

<style>
.add-modal .modal .modal-header {
  padding: 22px 22px 0;
  border: none;
}
.add-modal .modal .modal-header .btn-close{
  font-size: 12px;
    opacity: .3;
}
.add-modal .modal h1 {
  font-size: 1.75rem;
}

.add-modal .modal .modal-content {
  border-radius: .6rem;
  box-shadow: 1px 1px 5px #856161;
  width: 650px;
  padding: 0 3.75rem;
}
.add-modal .modal .modal-title {
  color: #071437;
  font-weight: 500;
}
.add-modal .modal .no-btn {
  background-color: #f9f9f9;
  color: #252f4a;
}
.add-modal .modal .yes-btn {
  background-color: #1b84ff;
  color: #fff;
}
.add-modal .card {
  border: none;
}
.add-modal .modal .no-btn:hover,
.add-modal .modal .yes-btn:hover {
  box-shadow: 1px 1px 5px #ccc;
}
.add-modal .card .card-body .mb-3 {
  text-align: left;
}
.add-modal .card .card-body label span{
  color: red;
}
.add-modal .card .card-body label {
  font-weight: 400;
  color: #071437;
  font-size: 14px;
}
.add-modal .card .card-body input {
  outline: none;
  border-radius: 4px;
  background: transparent;
  margin-bottom: 2rem;
}
.add-modal .card .card-body input::placeholder {
  font-size: 0.8rem;
  color: #adafb4;
}
.add-modal .card .card-body input:focus {
  box-shadow: none !important;
  border: 1px solid #1b84ff !important;
}
.add-modal .card .modal-footer{
  justify-content: center;
    border: none;
}
@media screen and (max-width: 991px) {
  .add-modal .modal .modal-content {
    width: 100% !important;
  }
}
</style>
